import { useState, useEffect, useMemo } from 'react'
import axios from '../../tools/axiosWrapper'
import _ from 'lodash'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Link } from 'react-router-dom'
import { Route, Routes, Outlet, useLocation, Navigate } from 'react-router-dom'
import { ArchContext } from './context.js'

import Me from '../me/me.js'
import Library from '../library/library.js'
import Desk from '../desk/desk.js'
import ImgViewer from '../desk/imgviewer/imgviewer'

const User = ({ location }) => {
  const [_me, set_me] = useState(null)
  const [_my, set_my] = useState([])
  const [_up, set_up] = useState(0)
  const [reload, set_reload] = useState(0)
  const [profileVisible, setProfileVisible] = useState(1)

  const [id, set_id] = useState(null)
  const value = useMemo(() => ({ id, set_id }), [id, set_id])

  const upstate = {
    before: {
      text: '___',
    },
    updating: {
      text: 'updating...',
    },
    updated: {
      text: 'updated',
    },
  }

  useEffect(() => {
    axios.get('/auth/me').then((res) => {
      set_me(res.data)
    })

    axios.get('/doc/listme').then((res) => {
      const sorted = res.data.contents
      sorted.forEach((i) => {
        i.created = i.headings[0].replace('inittime=', '')
      })
      set_my(_.sortBy(sorted, 'created').reverse())
    })
  }, [])

  return (
    <div className='main'>
      <ArchContext.Provider value={value}>
        <Me
          _me={_me}
          _my={_my}
          set_my={set_my}
          reload={reload}
          set_reload={set_reload}
          profileVisible={profileVisible}
          setProfileVisible={setProfileVisible}
        />
        <Routes>
          <Route
            index
            element={<Library _me={_me} profileVisible={profileVisible} />}
          />
          <Route
            path='/doc/:slug'
            element={
              <Desk
                _my={_my}
                set_my={set_my}
                location={location}
                profileVisible={profileVisible}
              />
            }
          />
          <Route
            path='/img/:slug'
            element={
              <ImgViewer
                _my={_my}
                set_my={set_my}
                location={location}
                profileVisible={profileVisible}
              />
            }
          />
        </Routes>
      </ArchContext.Provider>
    </div>
  )
}

export default User
