import axios from 'axios'

const $axios = axios.create({
  withCredentials: true,
})

$axios.defaults.baseURL = process.env.REACT_APP_API_URL
// $axios.defaults.baseURL = 'http://localhost:8000/api'

export default $axios
