export const ids = [
  {
    "id": "odoc000103w35",
    "title": "Virality reduction as a harm mitigation strategy"
  },
  {
    "id": "odoc000104w35",
    "title": "Dangerous Growth of Civic Groups"
  },
  {
    "id": "odoc000102w35",
    "title": "worth your time ranking change"
  },
  {
    "id": "odoc000101w35",
    "title": "Inauthentic Behavior Problem Overlap Analysis"
  },
  {
    "id": "odoc000203w35",
    "title": "Misinfo x Virality Analysis"
  },
  {
    "id": "odoc000204w35",
    "title": "Fighting high harm misinfo with deep reshare damping"
  },
  {
    "id": "odoc000202w35",
    "title": "Reshare Depth by Country"
  },
  {
    "id": "odoc000216w35",
    "title": "[Person] Collection of Posts"
  },
  {
    "id": "odoc000209w35",
    "title": "Sophie Zhang -- exit post"
  },
  {
    "id": "odoc000201w35",
    "title": "reshare ranking exp in india and indonesia"
  },
  {
    "id": "odoc000215w35",
    "title": "Sophie Zang -- big post"
  },
  {
    "id": "odoc000213w35",
    "title": "Reshare depth experiement in myanmar"
  },
  {
    "id": "odoc000409w35",
    "title": "Maturity Framework"
  },
  {
    "id": "odoc000401w35",
    "title": "Random [Person]"
  },
  {
    "id": "odoc000620w35",
    "title": "Harmful Topic Communities"
  },
  {
    "id": "odoc000619w35",
    "title": "RTB Opex Business Review"
  },
  {
    "id": "odoc000607w35",
    "title": "Stop the Steal and Patriot Party"
  },
  {
    "id": "odoc000609w35",
    "title": "[Person] - Going Away Post"
  },
  {
    "id": "odoc000608w35",
    "title": "Harmful conspiracy theories"
  },
  {
    "id": "odoc000606w35",
    "title": "CFO Lookbak and Forward"
  },
  {
    "id": "odoc000614w35",
    "title": "Coordinated Social Harm"
  },
  {
    "id": "odoc000704w35",
    "title": "Lotus Mahal Investigation"
  },
  {
    "id": "odoc000705w35",
    "title": "Unlabeled Pages"
  },
  {
    "id": "odoc000702w35",
    "title": "Inauthentic behavior verticals"
  },
  {
    "id": "odoc000709w35",
    "title": "Crisis Detection Pillar Lookback"
  },
  {
    "id": "odoc000701w35",
    "title": "Civic Summit Q1 2020"
  },
  {
    "id": "odoc000706w35",
    "title": "XCheck Timeline"
  },
  {
    "id": "odoc000850w35",
    "title": "[Person] - statistical tests"
  },
  {
    "id": "odoc000803w35",
    "title": "Guy Rosen - Reorg Integrity - Crowd Tangle"
  },
  {
    "id": "odoc000804w35",
    "title": "High Quality News doesn't protect you from polarization"
  },
  {
    "id": "odoc000835w35",
    "title": "False choices -- take down or leave up"
  },
  {
    "id": "odoc000851w35",
    "title": "Local News and Sports create a high number of cross-cutting comment likes"
  },
  {
    "id": "odoc000833w35",
    "title": "[Person] - deamplification"
  },
  {
    "id": "odoc000805w35",
    "title": "When User engagement Doesn't Equal User Value"
  },
  {
    "id": "odoc000818w35",
    "title": "Political diversity group founder went straight to Fox after quitting"
  },
  {
    "id": "odoc000827w35",
    "title": "[Person] Last Day at Facebook -- Badge Post"
  },
  {
    "id": "odoc000829w35",
    "title": "Sparing Sharing Deprecation Audit"
  },
  {
    "id": "odoc000842w35",
    "title": "bobo armadilllo private logging"
  },
  {
    "id": "odoc000845w35",
    "title": "users prefer civic comments wth diverse positve engagmeent"
  },
  {
    "id": "odoc000828w35",
    "title": "Ownership of Project"
  },
  {
    "id": "odoc000810w35",
    "title": "Misinfo narrowcasting on IG Home"
  },
  {
    "id": "odoc000826w35",
    "title": "Update on Political publisher issues"
  },
  {
    "id": "odoc000844w35",
    "title": "Employee concerns on political misinfo policies"
  },
  {
    "id": "odoc000843w35",
    "title": "Effects of politican shared misinformation"
  },
  {
    "id": "odoc000807w35",
    "title": "Measuring Human Perception to Defend Democracy"
  },
  {
    "id": "odoc000838w35",
    "title": "Diverse Engagement is an agile lever to promote integrity"
  },
  {
    "id": "odoc000801w35",
    "title": "Understanding the dangers of harmful topic communities"
  },
  {
    "id": "odoc000839w35",
    "title": "Comparing the effects of misinfo from politicians vs ordinary user sources"
  },
  {
    "id": "odoc000846w35",
    "title": "Identifying Narrowcast Misinfo Hard because missing or miscalibrated doubt"
  },
  {
    "id": "odoc000815w35",
    "title": "Killswitch Plan for all Group Recommendation Surfaces"
  },
  {
    "id": "odoc000812w35",
    "title": "a firewall for content policy"
  },
  {
    "id": "odoc000849w35",
    "title": "Andrew Bosworth thoughts for 2020"
  },
  {
    "id": "odoc000840w35",
    "title": "Narrowcast Misinfo Prevelence Update"
  },
  {
    "id": "odoc000847w35",
    "title": "[Person] Badge Post 1"
  },
  {
    "id": "odoc000814w35",
    "title": "[Person] - tradeoffs"
  },
  {
    "id": "odoc000903w35",
    "title": "harm of content is worse than overenforcement"
  },
  {
    "id": "odoc000902w35",
    "title": "VPVs by Group Size and Concentration"
  },
  {
    "id": "odoc000920w35",
    "title": "Misinfo got worse in 2019"
  },
  {
    "id": "odoc000918w35",
    "title": "Discoverability"
  },
  {
    "id": "odoc000916w35",
    "title": "Harmful non-violating narratives"
  },
  {
    "id": "odoc000919w35",
    "title": "5 Integrity UI principles"
  },
  {
    "id": "odoc000907w35",
    "title": "deamplification roadmap 2021"
  },
  {
    "id": "odoc000908w35",
    "title": "Citybuilding as an integrity strategy"
  },
  {
    "id": "odoc000906w35",
    "title": "Anti-Vaxx Content"
  },
  {
    "id": "odoc000923w35",
    "title": "i-3 maturity framework"
  },
  {
    "id": "odoc000915w35",
    "title": "Polarization work from 2017 (common ground)"
  },
  {
    "id": "odoc000912w35",
    "title": "[Person] - study disparate product outcomes"
  },
  {
    "id": "odoc001003w35",
    "title": "How do political ads impact perceptions of FB"
  },
  {
    "id": "odoc001004w35",
    "title": "Populations at Risk for 2020"
  },
  {
    "id": "odoc001002w35",
    "title": "Extremely civic segments get most misinfo"
  },
  {
    "id": "odoc001018w35",
    "title": "Cambodia examples"
  },
  {
    "id": "odoc001011w35",
    "title": "Identifying narrowcast content"
  },
  {
    "id": "odoc001016w35",
    "title": "Narrowcast Misinfo on IG"
  },
  {
    "id": "odoc001017w35",
    "title": "serial misinfo and hate offenders"
  },
  {
    "id": "odoc001010w35",
    "title": "Concentration of narrowcast segments"
  },
  {
    "id": "odoc001019w35",
    "title": "leveraging other teams classifiers for misinfo"
  },
  {
    "id": "odoc001021w35",
    "title": "Miscalibrated Expressions of Doubt and narrowcasting"
  },
  {
    "id": "odoc001001w35",
    "title": "Barriers to learning on FB"
  },
  {
    "id": "odoc001012w35",
    "title": "appeal rate per country"
  },
  {
    "id": "odoc001013w35",
    "title": "Notes from responsible recommender group"
  },
  {
    "id": "odoc001014w35",
    "title": "3 Segments disproportionately received group interventions"
  },
  {
    "id": "odoc001104w35",
    "title": "proactivity framework"
  },
  {
    "id": "odoc001127w35",
    "title": "objective signals to capture like buzz feed"
  },
  {
    "id": "odoc001117w35",
    "title": "[Person] - exploited practice prevalence"
  },
  {
    "id": "odoc001119w35",
    "title": "low quality news exports targeting us"
  },
  {
    "id": "odoc001101w35",
    "title": "How to evaluate experimentation for Visitation"
  },
  {
    "id": "odoc001106w35",
    "title": "How communites are exploited - [Person]"
  },
  {
    "id": "odoc001115w35",
    "title": "lorax (XI:relevance:CVI:news"
  },
  {
    "id": "odoc001113w35",
    "title": "samidh - bending toward racial justice"
  },
  {
    "id": "odoc001114w35",
    "title": "egypt digital literacy outreach"
  },
  {
    "id": "odoc001203w35",
    "title": "[Person] - effects of sharing on DAP"
  },
  {
    "id": "odoc001204w35",
    "title": "Part 1 -- searching for and predicting page quality"
  },
  {
    "id": "odoc001205w35",
    "title": "[Person] - integrity choices and Like Data"
  },
  {
    "id": "odoc001202w35",
    "title": "[Person] - Making Web Platforms Resilient against Disinformation"
  },
  {
    "id": "odoc001211w35",
    "title": "Will Teens eventually adopt Facebook"
  },
  {
    "id": "odoc001216w35",
    "title": "[Person] - personalization Schmersonalization"
  },
  {
    "id": "odoc001210w35",
    "title": "[Person] - additional friends increase time spent, pages do not"
  },
  {
    "id": "odoc001209w35",
    "title": "[Person] - Measuring personalization through modeling user and content biases"
  },
  {
    "id": "odoc001208w35",
    "title": "[Person] - ratchet effects in growth of social apps"
  },
  {
    "id": "odoc001215w35",
    "title": "[Person] - measuring personalization through modeling bias terms"
  },
  {
    "id": "odoc001212w35",
    "title": "[Person] - What's new in feed recommendations?"
  },
  {
    "id": "odoc001213w35",
    "title": "[Person] - ML models and incentives for content producers"
  },
  {
    "id": "odoc001214w35",
    "title": "part 2 - searching for and predicting page quality"
  },
  {
    "id": "odoc001403w35",
    "title": "glossary of integrity terms"
  },
  {
    "id": "odoc001420w35",
    "title": "John Hegeman -- state of feed integrity Sept 2020"
  },
  {
    "id": "odoc001418w35",
    "title": "XCheck will no longer support whitelists and exemptions"
  },
  {
    "id": "odoc001416w35",
    "title": "FAST Camp -- Fast ranking:recommendaiton service"
  },
  {
    "id": "odoc001419w35",
    "title": "Harmful conspiracy theories -- what are they and who believes them?"
  },
  {
    "id": "odoc001421w35",
    "title": "social engagement helpful in countering misinfo"
  },
  {
    "id": "odoc001409w35",
    "title": "Connection between mental health and radicalization"
  },
  {
    "id": "odoc001408w35",
    "title": "They used to post selfies, now they're trying to reverse the election (NYT story commentary)"
  },
  {
    "id": "odoc001406w35",
    "title": "Disabling downstream models for civic and health"
  },
  {
    "id": "odoc001424w35",
    "title": "mistake prevention plan for XCheck exemption removal on complex objects"
  },
  {
    "id": "odoc001415w35",
    "title": "Auditing for heterogenous integirty in H2 2020"
  },
  {
    "id": "odoc001412w35",
    "title": "Auditing for heterogenous integirty -- H2 Roadmap"
  },
  {
    "id": "odoc001414w35",
    "title": "[Person] - Journey to QAnon"
  },
  {
    "id": "odoc001422w35",
    "title": "[Person] - top of mind April 26 2021"
  },
  {
    "id": "odoc001503w35",
    "title": "[Person] - reshare friction improves education and reduces integrity issues"
  },
  {
    "id": "odoc001505w35",
    "title": "List of Top N"
  },
  {
    "id": "odoc001502w35",
    "title": "Reduce Audit V2"
  },
  {
    "id": "odoc001520w35",
    "title": "Integrity Audit - update and next steps"
  },
  {
    "id": "odoc001518w35",
    "title": "Mark Feedback on soft actions proposal +Deck presented to Mark"
  },
  {
    "id": "odoc001527w35",
    "title": "whitelisting"
  },
  {
    "id": "odoc001511w35",
    "title": "[Person] - understanding how harmful content spreads on FB"
  },
  {
    "id": "odoc001529w35",
    "title": "2020 Call for Soft Actions"
  },
  {
    "id": "odoc001516w35",
    "title": "Top-N"
  },
  {
    "id": "odoc001517w35",
    "title": "Integrity audit -- more updates and next steps"
  },
  {
    "id": "odoc001519w35",
    "title": "DMARS H2 Plan"
  },
  {
    "id": "odoc001526w35",
    "title": "2021 stats on Top N content integrity VPVs"
  },
  {
    "id": "odoc001507w35",
    "title": "BI Page disable codes -- increasing enforcement transparency"
  },
  {
    "id": "odoc001530w35",
    "title": "24 Examples of Top N Audits"
  },
  {
    "id": "odoc001508w35",
    "title": "[Person] asking about BLS"
  },
  {
    "id": "odoc001501w35",
    "title": "Benefits and harms of targeted political speech framework and literature"
  },
  {
    "id": "odoc001506w35",
    "title": "2019 H2 Feed Enforcemnt Goals and principles"
  },
  {
    "id": "odoc001512w35",
    "title": "FB Public Reduce principles"
  },
  {
    "id": "odoc001522w35",
    "title": "No echo chambers on reddit"
  },
  {
    "id": "odoc001605w35",
    "title": "A Behavioural Heuristic of Problematic Use"
  },
  {
    "id": "odoc001602w35",
    "title": "Violent protests in pakistan"
  },
  {
    "id": "odoc001608w35",
    "title": "problematic faceboook use == when people feel FB negatively affects their life"
  },
  {
    "id": "odoc001606w35",
    "title": "ARC Coverage of integrity signals with comments"
  },
  {
    "id": "odoc001613w35",
    "title": "Sessions lead to problematic facebook behavior"
  },
  {
    "id": "odoc001614w35",
    "title": "Have we made people addicted to Facebook?"
  },
  {
    "id": "odoc001705w35",
    "title": "Civic Ranking Engagement-Based Worth Your Time"
  },
  {
    "id": "odoc001718w35",
    "title": "Misinfo and downstream measurement"
  },
  {
    "id": "odoc001716w35",
    "title": "Writeup on civic ranking (update)"
  },
  {
    "id": "odoc001717w35",
    "title": "[Person] April 2020 update"
  },
  {
    "id": "odoc001721w35",
    "title": "April 2020 Civic Misinfo SEV"
  },
  {
    "id": "odoc001701w35",
    "title": "Understanding this post's ranking -- how I miss thee"
  },
  {
    "id": "odoc001706w35",
    "title": "Assessing the impact of covid ranking improvements on perceptions, practices and sentiment"
  },
  {
    "id": "odoc001725w35",
    "title": "Clarifications about MSI:Session Metrics"
  },
  {
    "id": "odoc001804w35",
    "title": "April 30 Progress on News Feed Goaling"
  },
  {
    "id": "odoc001903w35",
    "title": "Launch plan -- disordered eating checkpoint V2"
  },
  {
    "id": "odoc001905w35",
    "title": "How do different types of feedback impact producer PR"
  },
  {
    "id": "odoc001902w35",
    "title": "How do managers of managers flourish -- PSC study"
  },
  {
    "id": "odoc001918w35",
    "title": "Interning at FB revived my eating disorder"
  },
  {
    "id": "odoc001911w35",
    "title": "Proactive Risk escalation -- eating disorders"
  },
  {
    "id": "odoc001917w35",
    "title": "IG Well being -- meaningful usage scope post"
  },
  {
    "id": "odoc001910w35",
    "title": "i3 reorg chart"
  },
  {
    "id": "odoc001907w35",
    "title": "How to be a bad actor on Facebook"
  },
  {
    "id": "odoc001901w35",
    "title": "Beyond the individual user -- understanding our products through the household ecosystem"
  },
  {
    "id": "odoc001906w35",
    "title": "Problematic Use of FB -- user journey, personas and opportunity mapping"
  },
  {
    "id": "odoc001923w35",
    "title": "How Behavior on IG varies with overall time spent"
  },
  {
    "id": "odoc001913w35",
    "title": "Thoughts on moving from impressions goal to engagement:feedback goal for creators"
  },
  {
    "id": "odoc002003w35",
    "title": "Actor Accountability GNN Predictions for SUMA:Actor accountability"
  },
  {
    "id": "odoc002005w35",
    "title": "Problematic Non violating narratives is an archetype in need of novel solutions"
  },
  {
    "id": "odoc002018w35",
    "title": "SUMAs and impact on civic space"
  },
  {
    "id": "odoc002016w35",
    "title": "MAP likely inflated for mature accounts"
  },
  {
    "id": "odoc002017w35",
    "title": "[Person] - Actor Level Restriction for IIC Tier Manual Disables"
  },
  {
    "id": "odoc002010w35",
    "title": "Validation of Post-level civic integrity surveys"
  },
  {
    "id": "odoc002019w35",
    "title": "Political content on Facebook (part 1) consumer experiences and how FB can help"
  },
  {
    "id": "odoc002009w35",
    "title": "Replacing downstream demotions on civic and health FAST followups"
  },
  {
    "id": "odoc002008w35",
    "title": "Understanding the impact of political content on FB experience and sentiment"
  },
  {
    "id": "odoc002001w35",
    "title": "Stratified Sampling for ARC and Bordeline"
  },
  {
    "id": "odoc002006w35",
    "title": "teen SUMA usage"
  },
  {
    "id": "odoc002015w35",
    "title": "product brief -- ranking for civic health"
  },
  {
    "id": "odoc002012w35",
    "title": "Multi-device usage -- multiple phones or sharing devices"
  },
  {
    "id": "odoc002013w35",
    "title": "SUMAs as account reset -- increasing engagement through creating a better account"
  },
  {
    "id": "odoc002103w35",
    "title": "Algorthmic Incentives (anti-democratic)"
  },
  {
    "id": "odoc002104w35",
    "title": "virality reduction as integrity strategy"
  },
  {
    "id": "odoc002105w35",
    "title": "perceived emotional impact of FB and key competitors"
  },
  {
    "id": "odoc002102w35",
    "title": "Responsibility, conceptually - scoping and definitions"
  },
  {
    "id": "odoc002111w35",
    "title": "understanding headers impact on Suma"
  },
  {
    "id": "odoc002116w35",
    "title": "integrity org chart"
  },
  {
    "id": "odoc002110w35",
    "title": "the friending 1% -- inequality in friending"
  },
  {
    "id": "odoc002115w35",
    "title": "[Person] - talking about the misinfo regression April 2019"
  },
  {
    "id": "odoc002112w35",
    "title": "facebook is the real world"
  },
  {
    "id": "odoc002113w35",
    "title": "rough opportunity sizing for vpv:read:interactions harms (the naughty list)"
  },
  {
    "id": "odoc002114w35",
    "title": "unifying models together for efficiency"
  },
  {
    "id": "odoc002204w35",
    "title": "Operationalizing inclusivity in data"
  },
  {
    "id": "odoc002202w35",
    "title": "Gini index analysis on civic posts"
  },
  {
    "id": "odoc002220w35",
    "title": "MSI first descriptive analysis"
  },
  {
    "id": "odoc002218w35",
    "title": "newsfeed groups diversity"
  },
  {
    "id": "odoc002211w35",
    "title": "US 2020 crisis risk leads prioritization"
  },
  {
    "id": "odoc002216w35",
    "title": "Library of Interventions"
  },
  {
    "id": "odoc002217w35",
    "title": "people in at risk countries rank hate speech, dangerous orgs and inciting content more severe"
  },
  {
    "id": "odoc002210w35",
    "title": "CAAP V4"
  },
  {
    "id": "odoc002221w35",
    "title": "IPOC integrations menu"
  },
  {
    "id": "odoc002207w35",
    "title": "IPOC master signals list"
  },
  {
    "id": "odoc002209w35",
    "title": "on manufactured virality, what it is, how prevalent it is and how we can reduce it"
  },
  {
    "id": "odoc002208w35",
    "title": "IPOC India and ethiopia hate speech and violence and incitement"
  },
  {
    "id": "odoc002215w35",
    "title": "better living through less inauthentic behavior"
  },
  {
    "id": "odoc002212w35",
    "title": "vespa in 2021"
  },
  {
    "id": "odoc002213w35",
    "title": "[Person] - learning on EU research"
  },
  {
    "id": "odoc002214w35",
    "title": "virality and harm lit review"
  },
  {
    "id": "odoc002303w35",
    "title": "[Person] - post Nov 2016 discussion thread"
  },
  {
    "id": "odoc002305w35",
    "title": "efficacy of political ads for donations"
  },
  {
    "id": "odoc002320w35",
    "title": "[Person] - Facebook donations"
  },
  {
    "id": "odoc002318w35",
    "title": "2020 integrity reorg - (end of civic)"
  },
  {
    "id": "odoc002311w35",
    "title": "Facebook is not neutral"
  },
  {
    "id": "odoc002316w35",
    "title": "political ads policy preview"
  },
  {
    "id": "odoc002317w35",
    "title": "civics for the entire FB family"
  },
  {
    "id": "odoc002310w35",
    "title": "Wellbeing Review h1 and h2 2020 with IG leads"
  },
  {
    "id": "odoc002319w35",
    "title": "Creating with compassion - reframing edge cases as sress cases"
  },
  {
    "id": "odoc002321w35",
    "title": "Mental Health is the 3rd teen topic in the US"
  },
  {
    "id": "odoc002307w35",
    "title": "Discussion about [Person] firing"
  },
  {
    "id": "odoc002309w35",
    "title": "Sheryl post -- joel kaplan at kavanaugh hearing"
  },
  {
    "id": "odoc002308w35",
    "title": "are we the baddies"
  },
  {
    "id": "odoc002306w35",
    "title": "Teen Wellbeing Foundational Research (international)"
  },
  {
    "id": "odoc002323w35",
    "title": "facebook frankenstein moment"
  },
  {
    "id": "odoc002315w35",
    "title": "Sizing teen sharing behavior in IG"
  },
  {
    "id": "odoc002314w35",
    "title": "Demand specific actions in response to buzz feed leak re misinfo and bias to help conservatives"
  },
  {
    "id": "odoc002322w35",
    "title": "[Person] - badge post 6"
  },
  {
    "id": "odoc002404w35",
    "title": "How do we know who is a teen"
  },
  {
    "id": "odoc002418w35",
    "title": "Teen Girls Body Image"
  },
  {
    "id": "odoc002427w35",
    "title": "How are Teens Doing on IG"
  },
  {
    "id": "odoc002417w35",
    "title": "Use of is-a-teen model"
  },
  {
    "id": "odoc002419w35",
    "title": "Hard life Moments on IG - Mental Health Deep Dive"
  },
  {
    "id": "odoc002426w35",
    "title": "appearance based social comparison"
  },
  {
    "id": "odoc002408w35",
    "title": "Strong negative social comparison on IG"
  },
  {
    "id": "odoc002424w35",
    "title": "Teens and preteens"
  },
  {
    "id": "odoc002535w35",
    "title": "Max reshare depth experiment"
  },
  {
    "id": "odoc002505w35",
    "title": "filtering group previews for non-rec groups"
  },
  {
    "id": "odoc002520w35",
    "title": "Revamping the anti-vax searchability query set with signal based method"
  },
  {
    "id": "odoc002518w35",
    "title": "political party response to '18 algorithmic change"
  },
  {
    "id": "odoc002528w35",
    "title": "Will groups still be recommended after being labeled non-rec"
  },
  {
    "id": "odoc002510w35",
    "title": "All that you ever wanted to know about reshares"
  },
  {
    "id": "odoc002507w35",
    "title": "Civic Targeting Risk Scores V2"
  },
  {
    "id": "odoc002531w35",
    "title": "CEDAU vs FUSS non-rec"
  },
  {
    "id": "odoc002512w35",
    "title": "If you could nerf any type of reshare, what would it be?"
  },
  {
    "id": "odoc002605w35",
    "title": "quantifying the concentration of reshares -- and thier VPVs among users"
  },
  {
    "id": "odoc002608w35",
    "title": "Demotions from community review are low accuracy in prevelence"
  },
  {
    "id": "odoc002704w35",
    "title": "What happens to FB when we hide groups?"
  },
  {
    "id": "odoc002728w35",
    "title": "self, friend, group or page. comment value depends on type and relevance to user"
  },
  {
    "id": "odoc002717w35",
    "title": "Understanding how Harmful Content Spreads on FB"
  },
  {
    "id": "odoc002710w35",
    "title": "Review of OGPS and Group rehsares and the group mulitpicker"
  },
  {
    "id": "odoc002736w35",
    "title": "does FB reward outrage"
  },
  {
    "id": "odoc002730w35",
    "title": "is multigroup picker Spammy"
  },
  {
    "id": "odoc002708w35",
    "title": "What we know about the continued decline in original groups sharing (OGPS)"
  },
  {
    "id": "odoc002724w35",
    "title": "Does OGPS drive engagement Where"
  },
  {
    "id": "odoc002723w35",
    "title": "political party response to the '18 algorithm change"
  },
  {
    "id": "odoc002712w35",
    "title": "Groups multipicker followup quesiton"
  },
  {
    "id": "odoc002714w35",
    "title": "[Person] summary of breaking the prism - how to make our platforms less polarizing"
  },
  {
    "id": "odoc002803w35",
    "title": "[Person] last post"
  },
  {
    "id": "odoc002835w35",
    "title": "Three examples of domestic servitude disruptions"
  },
  {
    "id": "odoc002805w35",
    "title": "Domestic servitude global analysis"
  },
  {
    "id": "odoc002818w35",
    "title": "Domestic servitude 2020 Q4 Investigation"
  },
  {
    "id": "odoc002827w35",
    "title": "[Person] bage post 2"
  },
  {
    "id": "odoc002816w35",
    "title": "[Person] - beyond arresting bad guys"
  },
  {
    "id": "odoc002817w35",
    "title": "Brick and Mortar"
  },
  {
    "id": "odoc002821w35",
    "title": "Apple escalation -- how we made it through this SEV and human traficking"
  },
  {
    "id": "odoc002807w35",
    "title": "Correlates for social capital in FB groups"
  },
  {
    "id": "odoc002809w35",
    "title": "Domestic servitude reading"
  },
  {
    "id": "odoc002837w35",
    "title": "Domestic servitude and tracking in the middle east"
  },
  {
    "id": "odoc002806w35",
    "title": "4Chan as ground zero"
  },
  {
    "id": "odoc002839w35",
    "title": "70 percent of users never or rarely post or share about political issus"
  },
  {
    "id": "odoc002824w35",
    "title": "only 1.1 percent of misinformation comes from COVID sides"
  },
  {
    "id": "odoc002823w35",
    "title": "Example of scramble in response to Trump's team's complaints"
  },
  {
    "id": "odoc002812w35",
    "title": "Human trafficking -- 2020 H1 i3 roadmap"
  },
  {
    "id": "odoc002814w35",
    "title": "adversarial harmful networks - review of the Qeero"
  },
  {
    "id": "odoc002903w35",
    "title": "covid classifier v2 language scores"
  },
  {
    "id": "odoc002902w35",
    "title": "vaccine hesitancy is twice as common in comments as in posts"
  },
  {
    "id": "odoc002911w35",
    "title": "Borderline Vaccine framework"
  },
  {
    "id": "odoc002909w35",
    "title": "misinformation in comments"
  },
  {
    "id": "odoc002901w35",
    "title": "covid classifier v1 language scores"
  },
  {
    "id": "odoc002906w35",
    "title": "backfire effect paper"
  },
  {
    "id": "odoc002912w35",
    "title": "comparing pro and anti vaccine comments"
  },
  {
    "id": "odoc003032w35",
    "title": "Andrew Bosworth Thoughts on 2020 - with tons of great commentary"
  },
  {
    "id": "odoc003018w35",
    "title": "Graph Evolution Read Holdout Clean Up"
  },
  {
    "id": "odoc003021w35",
    "title": "How lightweight Negative Feedback Ranking Changes could affect user perceptions of the news feed"
  },
  {
    "id": "odoc003007w35",
    "title": "Do Actor Level Histories Predict Whether Other Users Will Report Them"
  },
  {
    "id": "odoc003030w35",
    "title": "Misinformation in feed warnings -- findings from india"
  },
  {
    "id": "odoc003006w35",
    "title": "How Diverse Engagement May Identify Valuable Civic Comments"
  },
  {
    "id": "odoc003023w35",
    "title": "[Person] - integrity tradeoffs"
  },
  {
    "id": "odoc003022w35",
    "title": "facebook and responsibility"
  },
  {
    "id": "odoc003120w35",
    "title": "providing negative feedback should be easy and why this would be game changing for integrity"
  },
  {
    "id": "odoc003119w35",
    "title": "Civic forensics 11-2021"
  },
  {
    "id": "odoc003121w35",
    "title": "Facebook creating an echo chamber for the government is lying to us -- do we care"
  },
  {
    "id": "odoc003109w35",
    "title": "here is how FB groups touch lives all over the world"
  },
  {
    "id": "odoc003108w35",
    "title": "[Person] - our algorithms should give people voice"
  },
  {
    "id": "odoc003101w35",
    "title": "interventions aimed at resharing users"
  },
  {
    "id": "odoc003113w35",
    "title": "A drilldown analysis of MSI:VPV and distribution of expert posts"
  },
  {
    "id": "odoc003125w35",
    "title": "Sri Lanka Giant Groups Ballooning"
  },
  {
    "id": "odoc003234w35",
    "title": "Sizing the opportunity of Repeat Offender Strikes on Page Admins"
  },
  {
    "id": "odoc003227w35",
    "title": "Demotions transparency does not apriori increase legitimacy"
  },
  {
    "id": "odoc003211w35",
    "title": "Young adults X Fast Research"
  },
  {
    "id": "odoc003237w35",
    "title": "Deamplify content from those who repeatedly post misinformation"
  },
  {
    "id": "odoc003208w35",
    "title": "Discussion on Leaking"
  },
  {
    "id": "odoc003206w35",
    "title": "Lessons from doing random Demotions - limitations of demotions"
  },
  {
    "id": "odoc003317w35",
    "title": "[Person] - random stub post - unhealthy addictive groups"
  },
  {
    "id": "odoc003308w35",
    "title": "The negative side of growth in groups"
  },
  {
    "id": "odoc003403w35",
    "title": "replacing blurry - h2 2021 violating hostile speech language prioritization"
  },
  {
    "id": "odoc003429w35",
    "title": "Original policy re political ads before Mark"
  },
  {
    "id": "odoc003428w35",
    "title": "Opt in power user experience with focus on hard news"
  },
  {
    "id": "odoc003410w35",
    "title": "[Person] - badge post 5"
  },
  {
    "id": "odoc003438w35",
    "title": "[Person] - comprehensive guide to becoming a video publisher"
  },
  {
    "id": "odoc003415w35",
    "title": "notes on digital literacy in myanman"
  },
  {
    "id": "odoc003412w35",
    "title": "Group Recs for low signal"
  },
  {
    "id": "odoc003504w35",
    "title": "Mock Performance Review written as Charles Sully Sullenberger, example of lionizing firefighters"
  },
  {
    "id": "odoc003535w35",
    "title": "Addressing Newsworthy Violating Content, replacing Blurry, adding comments"
  },
  {
    "id": "odoc003518w35",
    "title": "Reduce and Inform -- surfacing repeatedly fact-checked hoaxes with CIRD and regular expressions"
  },
  {
    "id": "odoc003527w35",
    "title": "Analyzing possibly harassing comments posted on german poliiticians accounts, 30 percent are violating"
  },
  {
    "id": "odoc003529w35",
    "title": "Mike Schroperfer - What's Slowing you Down, huge discussion about lack of proactivity and fireman arsonists"
  },
  {
    "id": "odoc003516w35",
    "title": "merging i2 into I and R"
  },
  {
    "id": "odoc003528w35",
    "title": "India Regional elections 2021 BTG Tracker"
  },
  {
    "id": "odoc003521w35",
    "title": "2020 civic classifier heuristic coverage"
  },
  {
    "id": "odoc003508w35",
    "title": "india ipoc phase 4 retro"
  },
  {
    "id": "odoc003506w35",
    "title": "how do we pull one break glass lever -- problems in myanmar"
  },
  {
    "id": "odoc003524w35",
    "title": "[Person] - a modest proposal for improving for demotion measurement"
  },
  {
    "id": "odoc003514w35",
    "title": "improving labeling:coverage by clustering"
  },
  {
    "id": "odoc003522w35",
    "title": "Response to events in early January 2021 -- lessons for algorithmic interventions"
  },
  {
    "id": "odoc003603w35",
    "title": "Where do top civic pages get their audience from part 1"
  },
  {
    "id": "odoc003651w35",
    "title": "Facebook US Sharing market share amoung young adults and coastal users - at a lower baseline but not getting particularly worse"
  },
  {
    "id": "odoc003634w35",
    "title": "Non-verifiably false misinformation - mismatch on user wants and Facebook ability"
  },
  {
    "id": "odoc003605w35",
    "title": "The Relationship that has Changed - Early Adopters, Facebook and Feed Sharing"
  },
  {
    "id": "odoc003618w35",
    "title": "Opportunity to gain sessions on young adults and low-friend-count users through APR, IFR all public rank"
  },
  {
    "id": "odoc003628w35",
    "title": "Example of usage of I support vs I manage"
  },
  {
    "id": "odoc003617w35",
    "title": "Teenagers (13-17) and Young Adults (18-22) in the US find In Feed Recommendations more worth their time and gain the most sessions from IFR"
  },
  {
    "id": "odoc003643w35",
    "title": "Playing Both Sides but in Ads"
  },
  {
    "id": "odoc003607w35",
    "title": "What is Shielded High Priority NA"
  },
  {
    "id": "odoc003609w35",
    "title": "Stoping the LIne on Civic Metrics Harm - Post saying Guard rails are not working"
  },
  {
    "id": "odoc003630w35",
    "title": "Project Daisy, Likes and Social Comparison"
  },
  {
    "id": "odoc003601w35",
    "title": "Playing Both Sides 2018-04-10"
  },
  {
    "id": "odoc003624w35",
    "title": "Where do top Civic Pages get their followers from, part 2"
  },
  {
    "id": "odoc003614w35",
    "title": "Karen and The Echo Chamber of Reshares -- a test user study of misinfo and polarization, liberal"
  },
  {
    "id": "odoc003622w35",
    "title": "Limitations of metrics, comments are relevant part"
  },
  {
    "id": "odoc003625w35",
    "title": "Social comparisons on IG -- Jan 2021"
  },
  {
    "id": "odoc003705w35",
    "title": "project daisy initial version of deck presented to Mark"
  },
  {
    "id": "odoc003702w35",
    "title": "The future of young adults and FAST"
  },
  {
    "id": "odoc003720w35",
    "title": "Analysis of feed composition and what kind of content US teens benefit from"
  },
  {
    "id": "odoc003718w35",
    "title": "Teen meaningful interactions and feed post feedback -- focus group"
  },
  {
    "id": "odoc003716w35",
    "title": "US teens and YA primarilys ee other teens and YAs through friend reshares"
  },
  {
    "id": "odoc003717w35",
    "title": "US Young adults sentiment analysis"
  },
  {
    "id": "odoc003710w35",
    "title": "March 2021 -- state of teens and young adults on FB and Insta"
  },
  {
    "id": "odoc003719w35",
    "title": "US and CA young adults \"worth your time\" survey analysis"
  },
  {
    "id": "odoc003721w35",
    "title": "Over time usage data"
  },
  {
    "id": "odoc003707w35",
    "title": "Finding friends on FB for young adults"
  },
  {
    "id": "odoc003709w35",
    "title": "US app saturation trends by age"
  },
  {
    "id": "odoc003708w35",
    "title": "[Person] - visualization of metrics across countries"
  },
  {
    "id": "odoc003701w35",
    "title": "project daisy -- deck presented to Mark Revision history"
  },
  {
    "id": "odoc003706w35",
    "title": "Young Adults FB Strategy Nov 2020"
  },
  {
    "id": "odoc003724w35",
    "title": "young adults data insights header"
  },
  {
    "id": "odoc003723w35",
    "title": "state of teens, june 2018"
  },
  {
    "id": "odoc003715w35",
    "title": "US 2020 growth has come from NY and ca coming back on FB during COVID"
  },
  {
    "id": "odoc003712w35",
    "title": "2017 Teen engagement decline update"
  },
  {
    "id": "odoc003714w35",
    "title": "Project Daisy -- version presented to Mark"
  },
  {
    "id": "odoc003722w35",
    "title": "total sharing participation rate -- decline begins"
  },
  {
    "id": "odoc003803w35",
    "title": "Prod Ops analysis -- creator monetization"
  },
  {
    "id": "odoc003804w35",
    "title": "Equity User Signal Task - Raw Data"
  },
  {
    "id": "odoc003805w35",
    "title": "Example ARC prioritization"
  },
  {
    "id": "odoc003820w35",
    "title": "Sharing current priorities for the social impact equity team"
  },
  {
    "id": "odoc003818w35",
    "title": "Arabic dialect representation in markets"
  },
  {
    "id": "odoc003811w35",
    "title": "Growth x Equity User Feedback"
  },
  {
    "id": "odoc003816w35",
    "title": "Civic integrity countries prioritization 2019"
  },
  {
    "id": "odoc003817w35",
    "title": "John rawls"
  },
  {
    "id": "odoc003810w35",
    "title": "Fairness and inclusivity in AI -- a maturity framework"
  },
  {
    "id": "odoc003819w35",
    "title": "sub regional representation in markets and classifiers -- arabic"
  },
  {
    "id": "odoc003821w35",
    "title": "Project green lantern"
  },
  {
    "id": "odoc003807w35",
    "title": "Producer fairness in content recommendation systems"
  },
  {
    "id": "odoc003809w35",
    "title": "Instagram Reels Favoring Badness"
  },
  {
    "id": "odoc003808w35",
    "title": "Equity for creators -- monetization equity"
  },
  {
    "id": "odoc003801w35",
    "title": "Renamed Racial Injustice Team to Equity Team"
  },
  {
    "id": "odoc003806w35",
    "title": "Facebook does not feature black publishers"
  },
  {
    "id": "odoc003824w35",
    "title": "The torillera incident"
  },
  {
    "id": "odoc003823w35",
    "title": "articulation of principles for allocation fairness decisions in FB ads"
  },
  {
    "id": "odoc003815w35",
    "title": "Fairness- relevant workplaces notes"
  },
  {
    "id": "odoc003812w35",
    "title": "The fairness maturity framework in AI"
  },
  {
    "id": "odoc003813w35",
    "title": "Example analysis for PT"
  },
  {
    "id": "odoc003814w35",
    "title": "model card template"
  },
  {
    "id": "odoc003822w35",
    "title": "H2 Market and classifier experiements"
  },
  {
    "id": "odoc003903w35",
    "title": "High impact changes to arabic systems"
  },
  {
    "id": "odoc003904w35",
    "title": "fairness in training data 2"
  },
  {
    "id": "odoc003932w35",
    "title": "Fairness assessment framework"
  },
  {
    "id": "odoc003905w35",
    "title": "Deriving MSI Weight"
  },
  {
    "id": "odoc003902w35",
    "title": "fairness in practice holiday edition"
  },
  {
    "id": "odoc003918w35",
    "title": "How to talk about fairness"
  },
  {
    "id": "odoc003927w35",
    "title": "Fairness in training data"
  },
  {
    "id": "odoc003911w35",
    "title": "Using zip codes for fairness"
  },
  {
    "id": "odoc003929w35",
    "title": "Gender and jobs research"
  },
  {
    "id": "odoc003916w35",
    "title": "Fairness condidtioning and commensurability"
  },
  {
    "id": "odoc003917w35",
    "title": "Fairness playbook"
  },
  {
    "id": "odoc003910w35",
    "title": "Bias in english language text"
  },
  {
    "id": "odoc003919w35",
    "title": "Measuring embedding fairness"
  },
  {
    "id": "odoc003926w35",
    "title": "Wrongful enforcement on BBC arabic head"
  },
  {
    "id": "odoc003921w35",
    "title": "Fairness competitive analysis"
  },
  {
    "id": "odoc003907w35",
    "title": "Fairness outcomes on jobs product"
  },
  {
    "id": "odoc003909w35",
    "title": "Fairness outcomes - AC priv"
  },
  {
    "id": "odoc003931w35",
    "title": "Instagram impresions per media"
  },
  {
    "id": "odoc003930w35",
    "title": "Fairness groupings"
  },
  {
    "id": "odoc003908w35",
    "title": "Jobs near you -- AC Priv"
  },
  {
    "id": "odoc003901w35",
    "title": "Fairness assessment chain of trust"
  },
  {
    "id": "odoc003924w35",
    "title": "Using zip codes for instragram analysis"
  },
  {
    "id": "odoc003923w35",
    "title": "2019 app metrics"
  },
  {
    "id": "odoc003915w35",
    "title": "Machine learning synthetic equitabiltiy"
  },
  {
    "id": "odoc003912w35",
    "title": "DCI enforcement analysis"
  },
  {
    "id": "odoc003913w35",
    "title": "Why FB made time spent a topline metric for 2019"
  },
  {
    "id": "odoc003914w35",
    "title": "Community review fairness outcomes"
  },
  {
    "id": "odoc003922w35",
    "title": "Fairness interventions"
  },
  {
    "id": "odoc003925w35",
    "title": "Gender age and fairness on jobs"
  },
  {
    "id": "odoc004003w35",
    "title": "Measuring discrimination average vs edge cases"
  },
  {
    "id": "odoc004004w35",
    "title": "Reels prelaunch fairness review"
  },
  {
    "id": "odoc004002w35",
    "title": "you should query non-feed VPVs"
  },
  {
    "id": "odoc004020w35",
    "title": "Automation First"
  },
  {
    "id": "odoc004018w35",
    "title": "Validating zop codes to predict racial"
  },
  {
    "id": "odoc004011w35",
    "title": "Reels mimicry levers"
  },
  {
    "id": "odoc004016w35",
    "title": "Proposal to fix arabic language work"
  },
  {
    "id": "odoc004017w35",
    "title": "Automation first current stats"
  },
  {
    "id": "odoc004010w35",
    "title": "fairness lost in translation"
  },
  {
    "id": "odoc004019w35",
    "title": "levers for 10% IRV goal"
  },
  {
    "id": "odoc004026w35",
    "title": "Fairness and race"
  },
  {
    "id": "odoc004021w35",
    "title": "Using zip codes and census for insta"
  },
  {
    "id": "odoc004007w35",
    "title": "Reels and camera ad hoc data investigations"
  },
  {
    "id": "odoc004009w35",
    "title": "Diversity and inclusion AI"
  },
  {
    "id": "odoc004006w35",
    "title": "central direct community integrity"
  },
  {
    "id": "odoc004024w35",
    "title": "Fairness and FB dating"
  },
  {
    "id": "odoc004023w35",
    "title": "Rapid adaptation scenarios"
  },
  {
    "id": "odoc004012w35",
    "title": "Candela journey toward AI Fairnesss"
  },
  {
    "id": "odoc004013w35",
    "title": "Fairness office hours pilot"
  },
  {
    "id": "odoc004014w35",
    "title": "Fairness and politics"
  },
  {
    "id": "odoc004025w35",
    "title": "Ranking enforcement reports"
  },
  {
    "id": "odoc004103w35",
    "title": "Allowing third party camera flipping"
  },
  {
    "id": "odoc004104w35",
    "title": "concern about overenforcment on palestinian things"
  },
  {
    "id": "odoc004132w35",
    "title": "PACT -- cognitive testing"
  },
  {
    "id": "odoc004135w35",
    "title": "Classifying loneliness"
  },
  {
    "id": "odoc004133w35",
    "title": "scrapescore 2"
  },
  {
    "id": "odoc004105w35",
    "title": "Dangerous orgs responds to palestinian censorship concerns"
  },
  {
    "id": "odoc004102w35",
    "title": "Xcheck get well plan"
  },
  {
    "id": "odoc004120w35",
    "title": "Mistakes prevention incident list (some XCheck)"
  },
  {
    "id": "odoc004118w35",
    "title": "Concerns about bias in misinfo escalations"
  },
  {
    "id": "odoc004127w35",
    "title": "TRIPS and external overenforcement concerns"
  },
  {
    "id": "odoc004129w35",
    "title": "Querdunken experiment"
  },
  {
    "id": "odoc004116w35",
    "title": "FB Both Increases and decreases loneliness"
  },
  {
    "id": "odoc004128w35",
    "title": "policy input on ARC demotions"
  },
  {
    "id": "odoc004117w35",
    "title": "2021 Q1 integrity updates from Rosen"
  },
  {
    "id": "odoc004110w35",
    "title": "mistake prevention Xcheck get well plan"
  },
  {
    "id": "odoc004119w35",
    "title": "Silence on palestinian issues"
  },
  {
    "id": "odoc004121w35",
    "title": "XCheck AC Priv"
  },
  {
    "id": "odoc004107w35",
    "title": "undo palestinian content marked as disturbing"
  },
  {
    "id": "odoc004109w35",
    "title": "contextual actors"
  },
  {
    "id": "odoc004131w35",
    "title": "Shielding"
  },
  {
    "id": "odoc004130w35",
    "title": "voter suppresion outside the US"
  },
  {
    "id": "odoc004108w35",
    "title": "Scrapescore"
  },
  {
    "id": "odoc004101w35",
    "title": "israeli palestinian crisis IPOC"
  },
  {
    "id": "odoc004106w35",
    "title": "DC Harmful Topic Presentation"
  },
  {
    "id": "odoc004124w35",
    "title": "whitelist where we are and where we want to be 2019"
  },
  {
    "id": "odoc004123w35",
    "title": "Xcheck get well metrics"
  },
  {
    "id": "odoc004112w35",
    "title": "loneliness strategic vision"
  },
  {
    "id": "odoc004114w35",
    "title": "MAD - four definitions for the acronym"
  },
  {
    "id": "odoc004122w35",
    "title": "preharm banhammer"
  },
  {
    "id": "odoc004125w35",
    "title": "reels equity H1 2021"
  },
  {
    "id": "odoc004203w35",
    "title": "Kids tablet to phone graduation"
  },
  {
    "id": "odoc004204w35",
    "title": "Qualitative loneliness research"
  },
  {
    "id": "odoc004205w35",
    "title": "kids privacy and age appropriate design"
  },
  {
    "id": "odoc004202w35",
    "title": "kids and portal profiles"
  },
  {
    "id": "odoc004211w35",
    "title": "game pidgeon and tween conversations"
  },
  {
    "id": "odoc004216w35",
    "title": "Jobs IG can do for teens"
  },
  {
    "id": "odoc004217w35",
    "title": "Messenger Kids Leveling up"
  },
  {
    "id": "odoc004210w35",
    "title": "youth helix"
  },
  {
    "id": "odoc004207w35",
    "title": "Should we default teens into privacy settings"
  },
  {
    "id": "odoc004209w35",
    "title": "What motivates tweens to connect"
  },
  {
    "id": "odoc004208w35",
    "title": "Tweens competitive analysis"
  },
  {
    "id": "odoc004201w35",
    "title": "What motivates tweens to start conversation"
  },
  {
    "id": "odoc004215w35",
    "title": "Exploring playdates as a growth lever"
  },
  {
    "id": "odoc004212w35",
    "title": "Tweens and social media"
  },
  {
    "id": "odoc004213w35",
    "title": "Teen story producers are heavy blacklist users"
  },
  {
    "id": "odoc004214w35",
    "title": "Why teens and young adults choose insta"
  },
  {
    "id": "odoc004329w35",
    "title": "[Person] goodbye pic"
  },
  {
    "id": "odoc004323w35",
    "title": "Crossing the four year mark at FB, also Climate Change"
  },
  {
    "id": "odoc004315w35",
    "title": "Demotions in practice"
  },
  {
    "id": "odoc004404w35",
    "title": "Sustainably managing at-risk countries"
  },
  {
    "id": "odoc004435w35",
    "title": "Who do we let people threaten to run over protesters"
  },
  {
    "id": "odoc004434w35",
    "title": "Concern about bias in misinformation escalations"
  },
  {
    "id": "odoc004433w35",
    "title": "mapping the fairness landscape"
  },
  {
    "id": "odoc004405w35",
    "title": "Investigations across ARCs in hostile speech"
  },
  {
    "id": "odoc004402w35",
    "title": "India elections case study part 2"
  },
  {
    "id": "odoc004427w35",
    "title": "It is supposed to be hard -- Guy Rosen note"
  },
  {
    "id": "odoc004410w35",
    "title": "Covid 19. note March 10"
  },
  {
    "id": "odoc004441w35",
    "title": "[Person] badge post 4"
  },
  {
    "id": "odoc004425w35",
    "title": "Holdouts in adversarial experiments"
  },
  {
    "id": "odoc004504w35",
    "title": "FB Operator solutions"
  },
  {
    "id": "odoc004528w35",
    "title": "Platform Awareness : Digital Literacy Scores"
  },
  {
    "id": "odoc004544w35",
    "title": "FB4A and FB Lite"
  },
  {
    "id": "odoc004506w35",
    "title": "FB Workplace Business Customer Integrity"
  },
  {
    "id": "odoc004539w35",
    "title": "FB Safety launches sept 2020"
  },
  {
    "id": "odoc004541w35",
    "title": "Deamplification H1 2021 Roadmap"
  },
  {
    "id": "odoc004512w35",
    "title": "IG Body Image Literature review"
  },
  {
    "id": "odoc004540w35",
    "title": "IG mental health Summary Document"
  },
  {
    "id": "odoc004603w35",
    "title": "Why Being Fair isn't alwasy equitable"
  },
  {
    "id": "odoc004602w35",
    "title": "Group default configureation and strikes"
  },
  {
    "id": "odoc004611w35",
    "title": "Strike application problems"
  },
  {
    "id": "odoc004616w35",
    "title": "IAP late 2020 plans"
  },
  {
    "id": "odoc004619w35",
    "title": "suspicious accounts in the developing world"
  },
  {
    "id": "odoc004607w35",
    "title": "IAP Strike 2020 Lookback"
  },
  {
    "id": "odoc004609w35",
    "title": "Misinformation user repeat offender"
  },
  {
    "id": "odoc004606w35",
    "title": "Adding Civic Users to Xcheck"
  },
  {
    "id": "odoc004615w35",
    "title": "Strike Wiki and categories"
  },
  {
    "id": "odoc004612w35",
    "title": "Strike system governance"
  },
  {
    "id": "odoc004613w35",
    "title": "XCheck ready to support newsworthy informed treatment"
  },
  {
    "id": "odoc004704w35",
    "title": "IG Market placement and Teens"
  },
  {
    "id": "odoc004732w35",
    "title": "MSI note Part 2"
  },
  {
    "id": "odoc004733w35",
    "title": "Product Impact of Hubrid MSI Strategy"
  },
  {
    "id": "odoc004705w35",
    "title": "Lebanese Blogger death threats"
  },
  {
    "id": "odoc004702w35",
    "title": "MSI Revisited Part 5"
  },
  {
    "id": "odoc004720w35",
    "title": "Coordinated narrowcasting by state"
  },
  {
    "id": "odoc004718w35",
    "title": "Group time spent of expert"
  },
  {
    "id": "odoc004711w35",
    "title": "Guy named [person] talking about hiring and equity plans"
  },
  {
    "id": "odoc004726w35",
    "title": "Should we reduce the weight on stickers"
  },
  {
    "id": "odoc004721w35",
    "title": "Why we build feeds"
  },
  {
    "id": "odoc004707w35",
    "title": "Influence mapping research"
  },
  {
    "id": "odoc004738w35",
    "title": "Myanmar Language"
  },
  {
    "id": "odoc004736w35",
    "title": "Seeing is believing -- the power of misinfo via video"
  },
  {
    "id": "odoc004709w35",
    "title": "IG Feed Rec"
  },
  {
    "id": "odoc004731w35",
    "title": "Daschund (CORGI Knockoff)"
  },
  {
    "id": "odoc004737w35",
    "title": "Language and support for Ethiopia"
  },
  {
    "id": "odoc004708w35",
    "title": "User severity for hate speech in ARCs"
  },
  {
    "id": "odoc004706w35",
    "title": "Tier 1 an 2 ARC Language Gaps"
  },
  {
    "id": "odoc004739w35",
    "title": "Using USI (unwelcome social interactions)"
  },
  {
    "id": "odoc004724w35",
    "title": "MSI Documentation"
  },
  {
    "id": "odoc004723w35",
    "title": "MSI and Comments"
  },
  {
    "id": "odoc004712w35",
    "title": "Asking fact checkers to change their decisions"
  },
  {
    "id": "odoc004722w35",
    "title": "Examples of strike thresholds"
  },
  {
    "id": "odoc004801w35",
    "title": "Trump and Oversight Board"
  },
  {
    "id": "odoc004903w35",
    "title": "Public Conversations 20190208"
  },
  {
    "id": "odoc004902w35",
    "title": "An Intro to Influence Modeleing"
  },
  {
    "id": "odoc004918w35",
    "title": "2021 APAC Connectivity Strategy v02"
  },
  {
    "id": "odoc004911w35",
    "title": "2020 misinfo xfn"
  },
  {
    "id": "odoc004910w35",
    "title": "Experimentation 2 FC2021Q1"
  },
  {
    "id": "odoc004919w35",
    "title": "Feed Ranking FC2019Q3"
  },
  {
    "id": "odoc004926w35",
    "title": "Core Data Team IC Expectations 02-12-2018 FINAL"
  },
  {
    "id": "odoc004921w35",
    "title": "Core Data Science IC Expectations FINAL"
  },
  {
    "id": "odoc004909w35",
    "title": "Updates on Priority 2020 Workstreams"
  },
  {
    "id": "odoc004930w35",
    "title": "Core Machine Learning for Feed"
  },
  {
    "id": "odoc004924w35",
    "title": "Feed Ranking in Practice FC2019Q3"
  },
  {
    "id": "odoc004923w35",
    "title": "What is Success Against Foreign Interference"
  },
  {
    "id": "odoc004913w35",
    "title": "Ranking and Recommendation Services FC2019Q3"
  },
  {
    "id": "odoc004925w35",
    "title": "Espionage Case Workflow v2a"
  },
  {
    "id": "odoc005005w35",
    "title": "Comment Quality:Integrity Deltoid Metrics"
  },
  {
    "id": "odoc005011w35",
    "title": "Metric changes in the next couple of weeks to make MSI capture more useful social interactions"
  },
  {
    "id": "odoc005010w35",
    "title": "Filtering out click bait, bullying, and excessive comments from MSI from 2020-11-1 onwards"
  },
  {
    "id": "odoc005007w35",
    "title": "Commentor Capping II"
  },
  {
    "id": "odoc005009w35",
    "title": "Commentor Capping III"
  },
  {
    "id": "odoc005008w35",
    "title": "A Quick look at MSI Components and Integrity"
  },
  {
    "id": "odoc005012w35",
    "title": "Reaction weight Revisions 2020H2"
  },
  {
    "id": "odoc005103w35",
    "title": "Capitol Riot Response"
  },
  {
    "id": "odoc005101w35",
    "title": "SEGMENTS FOR VIOLENCE INCITING NETWORKS"
  },
  {
    "id": "odoc005203w35",
    "title": "Facebook Kowtowed to Conservatives and Got Nothing in Return"
  },
  {
    "id": "odoc005204w35",
    "title": "Civic Summit - GO Market Civic Risk Incubation"
  },
  {
    "id": "odoc005205w35",
    "title": "Influence Modeling"
  },
  {
    "id": "odoc005202w35",
    "title": "Core Data Team Expectations"
  },
  {
    "id": "odoc005220w35",
    "title": "Volume of Accounts Worldwide"
  },
  {
    "id": "odoc005218w35",
    "title": "Fact Checkers and Misinfo"
  },
  {
    "id": "odoc005211w35",
    "title": "Civic Summit - Talk to Friends and Family about 2020"
  },
  {
    "id": "odoc005216w35",
    "title": "US2020 Super Tuesday Update"
  },
  {
    "id": "odoc005217w35",
    "title": "Coronavirus and the Workplace"
  },
  {
    "id": "odoc005210w35",
    "title": "Civic Summit - Welcome"
  },
  {
    "id": "odoc005219w35",
    "title": "Civic Summit - APAC Threats and Opportunities for the Next Billion Users"
  },
  {
    "id": "odoc005221w35",
    "title": "US2020 Another Facebook Disinformation Election"
  },
  {
    "id": "odoc005223w35",
    "title": "COVID Screenshots"
  },
  {
    "id": "odoc005215w35",
    "title": "Civic Actors, Their Risks, Our Responsibility H12020"
  },
  {
    "id": "odoc005213w35",
    "title": "Bookmarks"
  },
  {
    "id": "odoc005214w35",
    "title": "Facebook App Metrics"
  },
  {
    "id": "odoc005222w35",
    "title": "How Communities are Exploited on Facebook"
  }
]