import { useState, useEffect, useRef } from 'react'

import Magnifier from 'react-magnifier'

const ViewImage = ({ _list, _now, panelView }) => {
  const ref = useRef(null)

  const [zoom, setZoom] = useState(1)

  return (
    <>
      <div
        className={'view-img'}
        onClick={() => setZoom(1 - zoom)}
        style={
          panelView
            ? { height: '100%', width: 'auto' }
            : { width: '100%', height: 'auto', margin: 'auto' }
        }
      >
        <Magnifier
          src={_list.length ? 'data:image/png;base64, ' + _list[_now].img : ''}
          mgShowOverflow={0}
          zoomFactor={1.3}
          mgBorderWidth={0}
          mgShape={'circle'}
          mgWidth={zoom ? 300 : 0}
          mgHeight={zoom ? 300 : 0}
        />
      </div>
    </>
  )
}

export default ViewImage
