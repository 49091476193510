import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    mode: 'dark',
    background: { default: '#131316' },
    success: { main: '#2E7d32', light: '#4caf50', dark: '#1b5e20' },
    primary: {
      main: '#C7C7D1',
      light: '#000',
      // light: '#99FFA0',
      dark: '#7EDD83',
    },
    text: { primary: '#C7C7D1', secondary: '#C7C7D1' },
  },
})
