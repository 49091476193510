import { useState, useEffect, useMemo, useContext } from 'react'
import { useForm } from 'react-hook-form'
import axios from '../../../tools/axiosWrapper'
import anime from 'animejs/lib/anime.es.js'
import _ from 'lodash'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ResizePanel from 'react-resize-panel'
import ViewImage from '../view/viewimage.js'
import Magnifier from 'react-magnifier'
import AddTag2 from './addtag2'

import './imgviewer.css'

import { ReactComponent as LockSVG } from '../../../media/ui_symbols/lock.svg'
import { ReactComponent as MoreSVG } from '../../../media/ui_symbols/more.svg'
import { ReactComponent as PlusSVG } from '../../../media/ui_symbols/plus.svg'
import { ReactComponent as DocSVG } from '../../../media/library/doc_symbol.svg'

import View from '../view/view.js'

const ImgViewer = ({ _my, set_my, location, profileVisible }) => {
  const [panelView, setPanelView] = useState(1)
  const [zoom, setZoom] = useState(1)
  const [_tags, set_tags] = useState([])

  const [img, setImg] = useState(null)
  const [imgText, setImgText] = useState(null)

  let imgid = (location.pathname.split('/')[3].endsWith('w32') || location.pathname.split('/')[3].endsWith('w35'))
    ? location.pathname.split('/')[3]
    : null

  useEffect(() => {
    axios.get('/img/test/' + imgid).then((res) => {
      setImg(res.data)
    })
    axios.get('/img/text/' + imgid).then((res) => {
      setImgText(res.data)
    })
  }, [])

  const { register, handleSubmit, setValue } = useForm()

  useEffect(() => {
    setValue('newtext', imgText ? imgText.text : '')
  }, [imgText])

  const mainStyles = profileVisible
    ? {
        marginLeft: '210px',
        width: 'calc(100vw - 210px)',
      }
    : {
        marginLeft: '0px',
        width: '100vw',
      }

  return (
    <>
      <main className='desk' style={mainStyles}>
        <div className='desk'>
          <div
            className='desk-head'
            style={{
              height: '40px',
              fontWeight: '20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>{imgid}</div>
            <AddTag2 current={imgid} />
          </div>

          <div className='desk-main'>
            <section className='view'>
              <div
                className='view-main'
                style={{
                  flexDirection: panelView ? 'column' : 'row',
                  height: '100%',
                }}
              >
                <div
                  className='view-controls'
                  style={{
                    top: '45px',
                    left: '4px',
                  }}
                >
                  <button
                    className={'view-toggle'}
                    onMouseLeave={(e) => {
                      anime({
                        targets: 'button.view-toggle',
                        easing: 'easeInQuad',
                        backgroundColor: 'rgba(255,255,255,0)',
                        duration: 200,
                        delay: 10,
                      })
                    }}
                    onMouseDown={(e) => {
                      anime
                        .timeline({
                          targets: 'button.view-toggle',
                          easing: 'easeOutQuad',
                        })
                        .add({
                          backgroundColor: 'rgba(255,255,255,.5)',
                          duration: 200,
                          delay: 10,
                        })
                      setPanelView(1 - panelView)
                    }}
                    onMouseUp={(e) => {
                      anime
                        .timeline({
                          targets: 'button.view-toggle',
                          easing: 'easeInQuad',
                        })
                        .add({
                          backgroundColor: 'rgba(255,255,255,0)',
                          duration: 200,
                          delay: 10,
                        })
                        .add(
                          {
                            keyframes: [
                              { border: '2px solid rgba(255,255,255,.8)' },
                              { border: '2px solid rgba(255,255,255,0)' },
                            ],
                            duration: 280,
                          },
                          '-=240'
                        )
                    }}
                  >
                    <svg width={'20'} viewBox='0 0 128 128'>
                      <g
                        style={{
                          fill: 'none',
                          stroke: 'white',
                          strokeLinecap: 'round',
                          strokeLineJoin: 'round',
                          strokeWidth: '8px',
                        }}
                      >
                        <rect x='9' y='19' width='110' height='90' rx='10' />
                        {!panelView ? (
                          <line x1='119' y1='64' x2='9' y2='64' />
                        ) : (
                          <line x1='64' y1='19' x2='64' y2='109' />
                        )}
                      </g>
                    </svg>
                  </button>
                </div>
                <ResizePanel
                  direction={panelView ? 's' : 'e'}
                  style={panelView ? { height: '50%' } : { height: '100%' }}
                >
                  <div
                    className={'view-img'}
                    onClick={() => setZoom(1 - zoom)}
                    style={
                      panelView
                        ? { height: '100%', width: 'auto' }
                        : { width: '100%', height: 'auto', margin: 'auto' }
                    }
                  >
                    <Magnifier
                      src={img ? 'data:image/png;base64, ' + img : ''}
                      mgShowOverflow={0}
                      zoomFactor={1.3}
                      mgBorderWidth={0}
                      mgShape={'circle'}
                      mgWidth={zoom ? 300 : 0}
                      mgHeight={zoom ? 300 : 0}
                    />
                  </div>
                </ResizePanel>
                <div className='view-text'>
                  <form className={'textalert'}>
                    <textarea
                      type='text'
                      {...register('newtext', { minLength: 2 })}
                    />
                  </form>

                  <div></div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  )
}

export default ImgViewer
