import { useState, useEffect, useContext } from 'react'

import { Link } from 'react-router-dom'
import { ArchContext } from '../../user/context.js'
import axios from '../../../tools/axiosWrapper'

const Documents = ({ type, list }) => {
  // const { id, set_id } = useContext(ArchContext);
  const [_item, set_item] = useState(null)

  useEffect(() => {
    if (!list) {
    } else {
      var list$ = list.map((i) => {
        return (
          <div className='book-cover'>
            <Link to={type.slug + '/' + i.id}>
              <div className='book'>
                {type.symbol}
                <div className='book-info'>
                  <div className='book-title'>{i.title}</div>
                  <div className='book-id'>{'doc  id: ' + i.id}</div>
                  {/* <div className='book-id'>
                    {'images: ' + i.images.length}
                  </div> */}
                </div>
              </div>
            </Link>
          </div>
        )
      })
      set_item(list$)
    }
  }, [list])

  return (
    <>
      <section className='shelf'>
        <div className='shelf-head'>
          {/*<h1 className='shelf-head'>{type.name}</h1>*/}
        </div>
        <div className='shelf-main'>
          <div className='shelf-fade'></div>
          <div className='shelf-list'>{_item}</div>
        </div>
      </section>
    </>
  )
}

export default Documents
