import { useState, useEffect, useContext } from 'react'
import axios from '../../../tools/axiosWrapper'

import { ReactComponent as PlusSVG } from '../../../media/ui_symbols/plus.svg'
import { SlideContext } from '../slideContext.js'

import _ from 'lodash'
import { useForm } from 'react-hook-form'
import './addtag.css'
import { Button, TextField } from '@mui/material'

const AddTag = ({ _tags, set_tags, _list, _now }) => {
  const { register, resetField, handleSubmit, watch } = useForm()

  const [submit, setSubmit] = useState(false)

  const onSubmit = () => {
    axios.post('/tag/create', {
      tagalert: ['custom', Date.now(), watch('newtag')],
      imageKey: _list[_now].id,
    })
    resetField('newtag')
    setSubmit(true)
    setTimeout(() => {
      setSubmit(false)
    }, 1000)
  }

  return (
    <form className={'addtag'}>
      <TextField
        type='text'
        sx={{ width: 500, color: 'white' }}
        {...register('newtag', { minLength: 1 })}
        placeholder={'custom alert'}
        id='standard-basic'
        label='custom alert'
        variant='standard'
      />

      <Button variant='outlined' onClick={() => onSubmit()}>
        submit
      </Button>
      {submit && <div style={{ padding: '10px' }}>submitted successfully</div>}
    </form>
  )
}

export default AddTag
