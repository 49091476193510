import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import './deskhead.css';

import { ReactComponent as RightArrowSVG } from '../../../media/ui_symbols/rightarrow2.svg'

const DeskHead = ({_src, id}) => {

  return(
    <>
    <div className='deskhead-text-box'>
      <div className='deskhead-text-name'>
        {_src.title}
      </div>
      <div className='deskhead-text-id'>
        {'id: ' + _src.documentid}
      </div>
    </div>
    <RightArrowSVG />
    <div className='deskhead-text-box'>
      <div className='deskhead-text-name'>
        {id ? id.id : ''}
      </div>
      <div className='deskhead-text-id'>
        {'-'}
      </div>
    </div>


    </>

  )

}



export default DeskHead
