import { useState, useEffect, useContext } from 'react'
import { useDrop } from 'react-dnd'
import axios from '../../tools/axiosWrapper'
import { Link, useNavigate } from 'react-router-dom'
import anime from 'animejs/lib/anime.es.js'

import './me.css'
import '../plan/plan.css'

import { ReactComponent as UserSVG } from '../../media/ui_symbols/user.svg'
import { ReactComponent as PlusSVG } from '../../media/ui_symbols/plus.svg'
import { ReactComponent as LogoSVG } from '../../media/brand/fbarchive/logo_white.svg'

import My from './my.js'
import Dot from './dot.js'

const Me = ({
  _me,
  _my,
  reload,
  set_reload,
  profileVisible,
  setProfileVisible,
}) => {
  const status = (s) => {
    s = s.split(' ')[1]
    return s == 'granted' ? '8DE07F' : s == 'denied' ? 'E66060' : 'D6A937'
  }

  // =======================================
  const stati = [
    {
      fill: '8DE07F',
      text: 'granted',
    },
    {
      fill: 'D6A937',
      text: 'pending',
    },
    {
      fill: 'E66060',
      text: 'denied',
    },
  ]
  const [_cycle, set_cycle] = useState(0)
  // =======================================

  const newArchive = () => {
    // axios
    //   .post(`/doc/create`,
    //     {
    //       title: '',
    //       images: [],
    //       headings: [`inittime=${Date.now()}`, '*--*', `updatetime=${Date.now()}` ]
    //     }).then(res => {
    //       navigate(`/user/doc/${res.data.split(' ')[7]}`)
    //       window.location.reload()
    //     })
  }

  const profilePosition = profileVisible
    ? {
        left: '0px',
      }
    : {
        left: '-220px',
      }

  let navigate = useNavigate()

  return (
    <>
      <div className='me-controls'>
        <div onClick={() => navigate('/user')} className='logo'>
          <LogoSVG />
        </div>

        <button
          className={'me-button'}
          onMouseDown={(e) => {
            setProfileVisible(1 - profileVisible)
          }}
        >
          <UserSVG />
        </button>
      </div>

      <section className='me' style={profilePosition}>
        <div className='me'>
          <h1 className='me'>
            {_me ? _me.first_name + ' ' + _me.last_name : ''}
          </h1>

          {/*}<div className='me-status'>
          { _me ? <Dot fill={ status(_me.status) } /> :  <Dot fill={'BCBCC8'} /> }
          <h3 className='me-status'>
            { _me ? _me.status : '_' }
          </h3>
        </div>*/}

          <div
            className='me-status'
            onClick={() => set_cycle((_cycle + 1) % 3)}
            style={{ cursor: 'pointer' }}
          >
            {_me ? <Dot fill={stati[_cycle].fill} /> : <Dot fill={'BCBCC8'} />}
            <h3 className='me-status'>
              {_me ? 'access ' + stati[_cycle].text : '_'}
            </h3>
          </div>

          <section className='my'>
            <div className='my'></div>
            <br />
            <div class='my-list'>
              {/* {_my.map((i) => (
                <My i={i} reload={reload} set_reload={set_reload} />
              ))} */}
            </div>
          </section>
        </div>
      </section>
    </>
  )
}

export default Me
