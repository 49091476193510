import axios from '../../../tools/axiosWrapper'

import _ from 'lodash'
import { useForm } from 'react-hook-form'

const AddTag2 = ({ current }) => {
  const { register, resetField, handleSubmit } = useForm()
  const onSubmit = (data) => {
    resetField('newtag')

    console.log(data)

    axios.post('/tag/create', {
      tagalert: ['custom', Date.now(), data],
      imageKey: current,
    })
  }

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur()
    }
  }

  return (
    <form
      className={'addtag'}
      onBlur={handleSubmit(onSubmit)}
      onKeyDown={(e) => keyPress(e)}
    >
      <input
        type='text'
        {...register('newtag', { minLength: 1 })}
        placeholder={'custom alert [enter to submit]'}
      />
    </form>
  )
}

export default AddTag2
