import { useState, useEffect, useMemo, useContext } from 'react'
import { useForm } from 'react-hook-form'
import axios from '../../tools/axiosWrapper'
import _ from 'lodash'
import './desk.css'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ArchContext } from '../user/context.js'
import { SlideContext } from './slideContext.js'

import Title from './deskhead/title.js'
import DeskHead from './deskhead/deskhead.js'

import Imgs from './imgs/imgs.js'
import View from './view/view.js'
import Wait from '../../tools/loading.js'

import { ReactComponent as LockSVG } from '../../media/ui_symbols/lock.svg'
import { ReactComponent as MoreSVG } from '../../media/ui_symbols/more.svg'
import { ReactComponent as PlusSVG } from '../../media/ui_symbols/plus.svg'
import { ReactComponent as DocSVG } from '../../media/library/doc_symbol.svg'

const Desk = ({ _my, set_my, location, profileVisible }) => {
  const { id, set_id } = useContext(ArchContext)

  const [_src, set_src] = useState(null)
  const [_waitlist, set_waitlist] = useState([])
  const [_list, set_list] = useState([])
  const [_listtext, set_listtext] = useState([])

  const [_now, set_now] = useState(0)

  const currentSlide = useMemo(() => {
    return _list[_now]
  }, [_now, _list])

  const getArch = (slug) => {
    axios.get('/doc/list/' + slug).then((res) => {
      var data = res.data.contents[0]
      set_src(data)
      try {
        Promise.all(
          data.images.map((key, index) => {
            return axios.get('/img/test/' + key).then((res) => {
              var item = { id: key, img: res.data, ind: index }
              set_waitlist((old) => [...old, item])
              return item
            })
          })
        ).then((i) => {
          set_list(i)
        })
      } catch {}
    })
  }

  const getArchText = (slug) => {
    axios.get('/doc/list/' + slug).then((res) => {
      var data = res.data.contents[0]
      try {
        Promise.all(
          data.images.map((key, index) => {
            return axios.get('/img/text/' + key).then((res) => {
              var item = {
                id: res.data.imageid,
                text: res.data.text,
                ind: index,
              }
              return item
            })
          })
        ).then((i) => {
          set_listtext(i)
        })
      } catch {}
    })
  }

  useEffect(() => {
    set_id(location.docid)
  }, [location])

  useMemo(() => {
    if (id && (id.includes('w32') || id.includes('w35'))) {
      getArch(id)
      getArchText(id)
    }
  }, [id, set_id])

  const original =
    _src && _src.headings
      ? _src.headings.some((i) => i.includes('*--*'))
        ? 1
        : 0
      : 0

  const mainStyles = profileVisible
    ? {
        marginLeft: '210px',
        width: 'calc(100vw - 210px)',
      }
    : {
        marginLeft: '0px',
        width: '100vw',
      }

  const divStyles = profileVisible
    ? {
        width: 'calc(100vw - 230px)',
      }
    : {
        width: '100vw',
      }

  return (
    <>
      <SlideContext.Provider value={currentSlide}>
        <main className='desk' style={mainStyles}>
          <div className='desk'>
            <div className='desk-head'>
              {/* (!original && _src) ? <LockSVG />  : <></> */}
              {_src ? <DeskHead _src={_src} id={_list[_now]} /> : <></>}
            </div>

            <div className='desk-main'>
              <section className='list'>
                <div className='list-head'>
                  <MoreSVG />
                </div>
                <div className='list-main'>
                  <div className='list-fade'></div>
                  <Imgs
                    _waitlist={_.sortBy(_.uniqBy(_waitlist, 'id'), 'ind')}
                    _list={_.sortBy(_list, 'ind')}
                    _now={_now}
                    _src={_src}
                    set_now={set_now}
                    set_my={set_my}
                  />
                </div>
              </section>

              <View
                _src={_src}
                _list={_list}
                _listtext={_listtext}
                _now={_now}
                _my={_my}
                set_now={set_now}
              />
            </div>
          </div>
        </main>
      </SlideContext.Provider>
    </>
  )
}

export default Desk
